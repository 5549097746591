<template>
  <div class="discount-list-view">
    <app-header icon="ticket-alt" title="Vouchers"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-content">
            <div class="row">
              <div class="col-5">
                <app-input
                  v-model="term"
                  placeholder="Buscar..."
                  label="Filtrar por código"
                  icon="search"
                >
                </app-input>
              </div>
            </div>
          </div>

          <div class="card-header-buttons">
            <router-link :to="{ name: 'discounts.create' }">
              <b-tooltip label="Novo voucher">
                <b-button type="is-primary" size="is-small" icon-left="plus">
                  Adicionar
                </b-button>
              </b-tooltip>
            </router-link>
          </div>
        </header>

        <div class="card-content">
          <b-table
            @page-change="onPageChange"
            :data="data"
            :total="total"
            :per-page="15"
            :loading="isLoading"
            paginated
            backend-pagination
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column v-slot="props" field="discount" label="Código">
              <b-tooltip
                :label="
                  props.row.invalid
                    ? 'Cupom invalidado'
                    : 'Cumpom ainda válido!'
                "
                position="is-right"
                :class="props.row.invalid ? 'invalid' : ''"
                >{{ props.row.code }}</b-tooltip
              >
            </b-table-column>

            <b-table-column v-slot="props" field="value" label="Valor">
              {{
                props.row.discount_type == 1
                  ? `R$ ${toMoney(props.row.value)}`
                  : `${props.row.value}%`
              }}
            </b-table-column>
            <b-table-column v-slot="props" field="value" label="Tipo">
              {{ props.row.discount_type == 1 ? 'DINHEIRO' : 'PERCENTUAL' }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="created_at"
              label="Data de criação"
            >
              {{ formatDate(props.row.created_at) }}
            </b-table-column>

            <b-table-column v-slot="props" field="due_date" label="Válido até">
              {{
                props.row.due_date
                  ? formatDate(props.row.due_date)
                  : 'Indefinido'
              }}
            </b-table-column>

            <b-table-column v-slot="props" label="Opções">
              <b-tooltip label="Deletar" class="m-1">
                <b-button
                  @click="onClickDelete(props.row.id)"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum voucher encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DiscountsService from '@/services/discounts.service';
import moment from 'moment';

export default {
  data: () => ({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    term: '',
  }),
  watch: {
    term(value) {
      this.term = value.toUpperCase();
      this.loadDiscounts();
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo voucher',
        message:
          'Tem certeza que deseja <b>excluir</b> este voucher? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir voucher',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      DiscountsService.destroy(id)
        .then(() => {
          this.$buefy.snackbar.open('Voucher excluído com sucesso.');
          this.loadDiscounts();
        })
        .catch(({ response }) => {
          if (typeof response != 'undefined' && response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir o voucher.',
              'error',
              4000
            );
          }
        });
    },
    loadDiscounts() {
      this.isLoading = true;

      DiscountsService.get({
        page: this.page,
        'filter[code]': this.term,
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = items;
          this.page = current_page;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    onPageChange(page) {
      this.page = page;
      this.loadDiscounts();
    },
    toMoney(value) {
      return parseFloat(value).toFixed(2).replace(',', '.').replace('.', ',');
    },
  },
  mounted() {
    this.loadDiscounts();
  },
};
</script>

<style lang="scss" scoped>
.invalid {
  text-decoration: line-through;
  color: gray;
}
</style>
